import React from "react";

export default function Buttonwhite({ href, button1, button2 }) {
  return (
    <button className="custom-button">
      <a href={href}>
        <span className="button1">{button1}</span>
        <span className="button2">{button2}</span>
      </a>
    </button>
  );
}
