import React from "react";
import { Link } from "react-router-dom";

import { mediasociaux } from "../constans"; // Import des liens de navigation
import { logo } from "../assets";

export default function Footer() {
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer>
      <div className="containerfooter">
        <div className="boxlogo">
          <img
            src={logo}
            className="logo"
            alt="logo"
            onClick={handleLogoClick}
          />
        </div>
        <div className="block">
          <Link to="/Contact" className="link" onClick={handleLogoClick}>
            Contact
          </Link>
          <Link className="link" onClick={handleLogoClick}>
            Devis
          </Link>
        </div>
        <h3 className="logo-name" onClick={handleLogoClick}>
          Olivier&nbsp;<span>|&nbsp;Menuiserie&nbsp;Mallet</span>
        </h3>
      </div>
      <div className="containerfooterbot">
        <div className="mediablock">
          {mediasociaux.map((media) => (
            <button key={media.id}>
              <Link to={media.href} className="mediaref">
                <img src={media.picture} alt="media" className="blockimg" />
              </Link>
            </button>
          ))}
        </div>
        <p className="mediablock">©Copyright. All rights reserved.</p>
      </div>
    </footer>
  );
}
