import React from "react";

import { Navbar, Carousel, Footer } from "../components";

import { galerie1, galerie2 } from "../constans"; // Import des liens de navigation

export default function Galerie() {
  return (
    <div>
      <Navbar />
      <div className="margin-galerie">
        <div className="txt-galerie">
          <p>
            Nos&nbsp;<span>Créations</span>
          </p>
        </div>
        <div className="border-galerie"></div>
        <Carousel images={galerie1} className="logos-slideleft" />
        <div className="border-galerie"></div>
        <Carousel images={galerie2} className="logos-slideright" />
      </div>
      <Footer />
    </div>
  );
}
