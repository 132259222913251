import React from "react";

import { Navbar, Backgroundpage, Carderror, Footer } from "../components";

export default function Error() {
  return (
    <div>
      <Navbar />
      <Backgroundpage />
      <Carderror />
      <Footer />
    </div>
  );
}
