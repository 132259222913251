import React from "react";

export default function EachServices({ services }) {
  return (
    <main className="mainservices">
      {services.map((service, index) => (
        <section
          key={service.id}
          className={`servicesblock ${index % 2 === 1 ? "reverse" : ""}`}
        >
          <div className="servicesblock-left">
            <div className="imgservices">
              <img src={service.picture} alt="interieurmaison" />
              <div
                className={`backgroundblur ${
                  index % 2 === 1 ? "backgroundblurreverse" : ""
                }`}
              ></div>
              <img
                src={service.picture}
                alt={service.name}
                className={`imgservicesleft ${
                  index % 2 === 1 ? "imgservicesleftreverse" : ""
                }`}
              />
            </div>
          </div>
          <div
            className={`servicesblock-right ${
              index % 2 === 1 ? "servicesblock-rightreverse" : ""
            }`}
          >
            <p className="servicesdonner">{service.name}</p>
            <div className="servicesborder"></div>
            <ul className="ultxtservices">
              {service.txt.map((text, index) => (
                <React.Fragment key={index}>
                  <li>{text}</li>
                  {index < service.txt.length - 1 && (
                    <>
                      <br />
                      <div className="servicesborder-under"></div>
                      <br />
                    </>
                  )}
                </React.Fragment>
              ))}
            </ul>
            <button className="contactdevis">
              <a href={service.href} className="contactdevishref">
                {service.button}
              </a>
            </button>
          </div>
        </section>
      ))}
    </main>
  );
}
