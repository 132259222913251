import React from "react";

import {
  Navbar,
  Backgroundpage,
  Buttonwhite,
  Client,
  Footer,
} from "../components";

import { avisclient } from "../constans"; // Import des liens de navigation
import { avisclient2 } from "../constans"; // Import des liens de navigation
import { txtClient } from "../constans"; // Import des liens de navigation
import { txtClient2 } from "../constans"; // Import des liens de navigation

export default function Home() {
  return (
    <main>
      <header className="containernavimg">
        <Navbar />
        <Backgroundpage />
        <div className="container-button">
          <Buttonwhite href="/A_Propos" button1="Découvrez" button2="-moi" />
          <Buttonwhite href="/Contact" button1="Devis" button2="" />
        </div>
      </header>
      <div className="container-client">
        <span className="satisfaction-1">Satisfaction&nbsp;</span>
        <span className="satisfaction-2">Client</span>
      </div>
      <Client avis={avisclient} txt={txtClient} className="custom-client1" />
      <Client avis={avisclient2} txt={txtClient2} className="custom-client" />
      <Footer />
    </main>
  );
}
