import React from "react";

import { Link } from "react-router-dom";

export default function Carderror() {
  return (
    <div className="bigcontainerbackground">
      <div className="containerbackground">
        <div className="txt txt-top">
          <div className="block-top">Ce chemin d'accès</div>
          <div className="block-bot">semble incorrect.</div>
        </div>
        <div className="txt txt-mid">404</div>

        <button>
          <Link className="buttonback" to="/">
            Retour à l'accueil
          </Link>
        </button>
      </div>
    </div>
  );
}
