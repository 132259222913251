import React, { useState } from "react";
import DOMPurify from "dompurify";

export default function Form() {
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    prenom: false,
    nom: false,
    phone: false,
    message: false,
  });
  const [envoiReussi, setEnvoiReussi] = useState(null);

  const nameRegex = /^[A-Za-z\s]+$/; // Lettres alphabétiques et espaces uniquement
  const phoneRegex =
    /^(0[1-9]([ .-]?[0-9]{2}){4}|(\+33|0033)[ .-]?[1-9]([ .-]?[0-9]{2}){4})$/;
  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true; // Ajoutez une variable pour suivre la validité du formulaire

    // Validez le prénom
    if (!prenom || !nameRegex.test(prenom)) {
      setErrors((prevErrors) => ({ ...prevErrors, prenom: true }));
      formIsValid = false;
    }

    // Validez le nom
    if (!nom || !nameRegex.test(nom)) {
      setErrors((prevErrors) => ({ ...prevErrors, nom: true }));
      formIsValid = false;
    }

    // Validez le numéro de téléphone
    if (!phone || !phoneRegex.test(phone)) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: true }));
      formIsValid = false;
    }

    if (!message) {
      setErrors((prevErrors) => ({ ...prevErrors, message: true }));
      formIsValid = false;
    }

    if (formIsValid) {
      const sanitizedMessage = DOMPurify.sanitize(message);

      const dataToSend = {
        prenom: prenom,
        nom: nom,
        phone: phone,
        message: sanitizedMessage,
      };

      setLoading(true);
      setErrors({ prenom: false, nom: false, phone: false, message: false });

      fetch("http://212.227.26.165/Contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(true);
          setEnvoiReussi(false);

          // Réinitialiser les champs du formulaire
          setTimeout(() => {
            setPrenom("");
            setNom("");
            setPhone("");
            setMessage("");

            setTimeout(() => {
              setLoading(false);
              setEnvoiReussi(true);
              setTimeout(() => {
                setEnvoiReussi(null);
              }, 2000); // Change this value to 5000 milliseconds (5 seconds)
            }, 2000); // Change this value to 5000 milliseconds (5 seconds)
          }, 1000);
        })
        .catch((error) => {
          console.error("Erreur lors de l'envoi de l'e-mail : " + error);
          setEnvoiReussi(false);
          setLoading(false);
          setTimeout(() => {
            setEnvoiReussi(null);
          }, 2000); // Change this value to 5000 milliseconds (5 seconds)
        });
    } else {
      setLoading(true);
      setEnvoiReussi(null);
      setTimeout(() => {
        setLoading(false);
        setEnvoiReussi(false);
        setTimeout(() => {
          setEnvoiReussi(null);
        }, 2000); // Change this value to 5000 milliseconds (5 seconds)
      }, 2000); // Change this value to 5000 milliseconds (5 seconds)
    }
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <label className="label-flex">
        Prénom
        <input
          type="text"
          className={`input-invisible ${errors.prenom ? "error" : ""}`}
          value={prenom}
          onChange={(e) => {
            setPrenom(e.target.value);
            setErrors((prevErrors) => ({ ...prevErrors, prenom: false }));
          }}
        />
        {errors.prenom && <p className="error-message">Ce champ est requis</p>}
      </label>
      <br />
      <label className="label-flex">
        Nom
        <input
          type="text"
          className={`input-invisible ${errors.nom ? "error" : ""}`}
          value={nom}
          onChange={(e) => {
            setNom(e.target.value);
            setErrors((prevErrors) => ({ ...prevErrors, nom: false }));
          }}
        />
        {errors.nom && <p className="error-message">Ce champ est requis</p>}
      </label>
      <br />
      <label className="label-flex">
        Téléphone
        <input
          type="text"
          className={`input-invisible ${errors.phone ? "error" : ""}`}
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            setErrors((prevErrors) => ({ ...prevErrors, phone: false }));
          }}
        />
        {errors.phone && <p className="error-message">Ce champ est requis</p>}
      </label>
      <br />
      <label className="label-flex">
        Message
        <textarea
          className={`input-invisible ${errors.message ? "error" : ""}`}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            setErrors((prevErrors) => ({ ...prevErrors, message: false }));
          }}
        />
        {errors.message && <p className="error-message">Ce champ est requis</p>}
      </label>
      <br />
      <button
        type="submit"
        className={`custom-button-contact ${loading ? "loading" : ""} ${
          envoiReussi === true
            ? "success-button"
            : envoiReussi === false
            ? "error-button"
            : ""
        }`}
      >
        {loading
          ? "Envoi en cours..."
          : envoiReussi === true
          ? "Envoyé avec succès !"
          : envoiReussi === false
          ? "Non envoyé"
          : "Envoyer"}
      </button>
    </form>
  );
}
