import { React, useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

export default function MyGoogleMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAQhQAm3swHUstugqjBWIqdCL-7sfCnveU",
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const center = useMemo(
    () => ({
      lat: 48.82095050719157,
      lng: 2.4812169693528157,
    }),
    []
  );
  return (
    <GoogleMap
      mapContainerStyle={{ height: "100%", width: "100%" }}
      center={center}
      zoom={12}
    >
      <MarkerF position={center} />
    </GoogleMap>
  );
}
