import React from "react";

import { Navbar, EachServices, Footer } from "../components";
import { services } from "../constans";

export default function Services() {
  return (
    <div>
      <Navbar />
      <div className="marginservices">
        <div className="txtservices">
          <p>
            Nos&nbsp;<span>Services</span>
          </p>
        </div>
        <div className="border-services"></div>
        <EachServices services={services} />
      </div>
      <Footer />
    </div>
  );
}
