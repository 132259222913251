import React from "react";
import { Link } from "react-router-dom";

import { mediasociaux } from "../constans"; // Import des liens de navigation

export default function Information() {
  return (
    <section className="information">
      <div className="info-background">
        <p className="info-big-txt">Nous Contacter</p>
      </div>
      <div className="info-background">
        <p className="info-txt">12 Boulevard de Polangis,</p>
        <p className="info-txt">94500 Champigny-sur-Marne</p>
        <div className="info-border"></div>
        <p className="info-txt">oliviermallet24@gmail.com</p>
        <div className="info-border"></div>
        <p className="info-txt">06 59 16 69 89</p>
        <div className="info-border"></div>
        <div className="mediablocks">
          {mediasociaux.map((media) => (
            <button key={media.id}>
              <Link to={media.href} className="mediarefs">
                <img src={media.picture} alt="media" className="blockimgs" />
              </Link>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}
