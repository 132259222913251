import React from "react";

import { fleche2 } from "../assets";

export default function Carousel({ images, className }) {
  return (
    <div className="container-carousel">
      <div className="logos">
        <div className="arrow-left">
          <img src={fleche2} alt="" className="arrow-in" />
        </div>
        <div className="arrow-right">
          <img src={fleche2} alt="" className="arrow-in" />
        </div>
        <div className={`logos-slide ${className}`}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image.picture}
              alt={`Voici l'imagee de ${index}`}
            />
          ))}
        </div>
        <div className={`logos-slide ${className}`}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image.picture}
              alt={`Voici l'imagee de ${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
