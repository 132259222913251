import React from "react";

import { Navbar, Footer } from "../components";

import { aproposbg } from "../assets";

export default function About() {
  return (
    <div>
      <Navbar />
      <div className="bigcontainerabout">
        <div className="backgroundleft">
          <div className="backgroundleft-1">
            <img src={aproposbg} alt="test" />
          </div>
          <div className="backgroundleft-2">
            <div className="back-one">
              <img src={aproposbg} alt="test" />
            </div>
            <div className="back-two">
              <img src={aproposbg} alt="test" />
            </div>
            <div className="back-three">
              <img src={aproposbg} alt="test" />
            </div>
          </div>
          <div className="backgroundleft-3">
            <img src={aproposbg} alt="test" />
          </div>
        </div>
        <div className="backgroundright">
          <div className="txt-top-about">
            <p className="txt-about-color">
              à propos <span>de moi</span>
            </p>
          </div>
          <div className="border-about"></div>
          <div className="txt-about">
            <p className="txt-about-color-bot">
              <span className="namebackground">Olivier Mallet</span>
              Bonjour, je suis Olivier Mallet, artisan menuisier de
              Champigny-sur-Marne.
              <br />
              <br />
              Tout d'abord, je tiens à préciser que je travaille le bois depuis
              plus longtemps que je ne peux me souvenir, mais cela ne signifie
              pas que je suis le "Mozart de la menuiserie". Non, non, je suis
              simplement un gars qui aime sculpter et façonner le bois pour en
              faire des choses qui, je l'espère, vous impressionneront. Et si
              vous n'êtes pas impressionné, eh bien, c'est peut-être parce que
              je suis encore en train d'apprendre, et c'est un processus qui ne
              s'arrête jamais.
              <br />
              <br />
              Ma modestie est ma deuxième nature. Vous ne me verrez pas clamer
              haut et fort que je suis le meilleur menuisier de
              Champigny-sur-Marne, même si mes clients ont parfois tendance à le
              dire. Je préfère travailler dans l'ombre, laissant mon travail
              parler pour lui-même. Si vous cherchez quelqu'un qui parle
              beaucoup de lui-même, vous devrez peut-être chercher ailleurs.
              <br />
              <br />
              Et parlons de l'honnêteté. Si je dis que je vais faire quelque
              chose, je le fais. Si je vous dis que je peux réaliser un projet,
              c'est que je le peux. Je n'aime pas les promesses en l'air ni les
              surprises désagréables. Alors, attendez-vous à un service honnête
              et transparent de ma part.
              <br />
              <br />
              Enfin, un peu d'humour. Disons simplement que si je peux
              transformer une planche de bois en une magnifique pièce de
              mobilier, je peux aussi transformer une journée grise en un petit
              moment de rire. Après tout, le bois ne doit pas être le seul à
              être sculpté, n'est-ce pas ? Alors, si vous avez des blagues à
              partager pendant que je travaille sur votre projet, n'hésitez pas.
              Rire, c'est bon pour le cœur, tout comme une belle étagère en
              chêne massif.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
