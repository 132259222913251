// import image
import {
  bgmenu,
  fox,
  interieurmaison,
  fb,
  insta,
  linkedin,
  whatsapp,
  agencement,
  menuiseriedessin,
  cuisine,
  dressing,
  rénovation,
  parquet,
  avatar1F,
  avatar2F,
  avatar3F,
  avatar4F,
  avatar1H,
  avatar2H,
  avatar3H,
  avatar4H,
  placard1,
  placard2,
  placard3,
  placard4,
  placard5,
  placard6,
  placard7,
  placard8,
  table,
  meubletele,
  bibliothèque,
  bibliothèque1,
} from "../assets";

export const navLinks = [
  {
    id: ``,
    title1: "Accu",
    title2: "eil",
  },
  {
    id: `A_Propos`,
    title1: `A Pro`,
    title2: `pos`,
  },
  {
    id: `Services`,
    title1: `Serv`,
    title2: `ices`,
  },
  {
    id: `Galerie`,
    title1: `Gal`,
    title2: `erie`,
  },
  {
    id: `Contact`,
    title1: `Con`,
    title2: `tact`,
  },
];

export const imagesbg = [
  { url: "/", image: bgmenu },
  { url: "/Contact", image: interieurmaison },
  { url: "/*", image: fox },
];

export const txtClient = [
  {
    clientsatisfait: (
      <div>
        Chez Olivier Mallet, artisan menuisier à Champigny-sur-Marne, la
        satisfaction de nos clients est notre priorité.
        <br />
        <br />
        Nos réalisations parlent d'elles-mêmes, que ce soit pour des cuisines
        sur mesure, des placards élégants ou des meubles uniques.
        <br />
        <br />
        Découvrez les avis authentiques de nos clients ci-dessous pour en savoir
        plus sur leur expérience avec Olivier Mallet, votre menuisier de
        confiance.
      </div>
    ),
  },
];

export const txtClient2 = [
  {
    clientsatisfait: (
      <div>
        Olivier Mallet, votre référence en menuiserie sur mesure à
        Champigny-sur-Marne, est fière de ses clients satisfaits.
        <br />
        <br />
        Découvrez les avis de nos clients ci-dessous pour comprendre pourquoi
        notre réputation
        <br />
        <br />
        dépasse les attentes à Champigny-sur-Marne.La satisfaction du client est
        notre motivation, et nous sommes là pour donner vie à vos idées en bois.
      </div>
    ),
  },
];

export const avisclient = [
  {
    id: `1`,
    firstname: `Baptiste`,
    lastname: `Cesbron`,
    picturemeuble: placard1,
    picturepersonne: avatar1H,
    star: `5`,
    description: `Qualité`,
    descriptionsecond: `Monsieur Mallet est un artisan très professionnel. Nous avons fais appel à lui à deux reprises et les résultats finaux sont extrêmement satisfaisants.
Il est également très réactif. Nous recommandons vivement ses services.`,
  },
  {
    id: `2`,
    firstname: `Lucas`,
    lastname: ``,
    picturemeuble: placard2,
    picturepersonne: avatar2H,
    star: `5`,
    description: `Professionnel`,
    descriptionsecond: `Nous sommes très satisfaits du travail de Monsieur Mallet pour la conception/réalisation/installation de notre bibliothèque/meuble TV sur mesure. Monsieur Mallet a été réactif, disponible et de bon conseil pour la conception (mise au point plan, choix du bois, du vernis, etc.). Livraison/installation rapide et dans les délais indiqués, pose soigneuse. Bonnes finitions. Nous sommes très contents de notre meuble et réfléchissons déjà au prochain !`,
  },
  {
    id: `3`,
    firstname: `Sabine`,
    lastname: `Joinvillaise`,
    picturemeuble: placard3,
    picturepersonne: avatar1F,
    star: `5`,
    description: `Rapport qualité-prix`,
    descriptionsecond: `M Mallet a trouvé une bonne solution adaptée pour ma porte d entrée pas trop onéreuse et il a refermé un toit ancien si bien qu on ne saurait dire ce qui a été fait a posteriori.  Je le recommande`,
  },
  {
    id: `4`,
    firstname: `Karlo`,
    lastname: `Krstic`,
    picturemeuble: placard4,
    picturepersonne: avatar3H,
    star: `5`,
    description: `Réactivité`,
    descriptionsecond: `Je recherchais depuis plusieurs semaines comment réaliser un petit meuble mais qui présentait d'importantes contraintes techniques (accessibilité pour le montage, démontable en partie pour accéder à une trappe de visite...etc...). Sur les conseils d'un ami, j'ai fait appel à M. MALLET pour la conception et la réalisation de ce meuble. Si je devais utiliser un mot : PARFAIT !! M.MALLET est un grand professionnel qui aime son métier, qui sait conseiller et qui est très très soigneux. Je referai appel à lui sans soucis pour mes prochains travaux de menuiserie !!`,
  },
  {
    id: `5`,
    firstname: `Sophie`,
    lastname: `Duong`,
    picturemeuble: placard5,
    picturepersonne: avatar2F,
    star: `5`,
    description: `Ponctualité`,
    descriptionsecond: `Nous avons fait appel à M. Mallet suite à la recommandation de notre beau frère pour la réalisation d’un dressing sur mesure avec plusieurs contraintes de profondeur.
Il a su s’adapter à ces contraintes et a été de très bons conseils tout au long du projet. Il a fait preuve de patience lors de nos nombreuses demandes de modification, son travail est très bien exécuté et avec soin.
C'est un excellent professionnel que nous n’hésiterons pas à recommander à nos proches et amis tant pour son travail que pour son rapport qualité/prix.
Merci Beaucoup M. Mallet pour ce beau travail soigné.`,
  },
  {
    id: `6`,
    firstname: `Valentine`,
    lastname: `Petry`,
    picturemeuble: placard6,
    picturepersonne: avatar3F,
    star: `5`,
    description: `Facile`,
    descriptionsecond: `Nous avons fait appel à Olivier Mallet pour concevoir une étagère sur mesure. Nous sommes très satisfaits : la communication a été fluide et facile, (il a compris mes dessins approximatifs) ; le résultat est comme nous l'imaginions et les délais courts (et conformes à ceux qu'il nous avait annoncés). Bref, nous recommandons !`,
  },
];

export const avisclient2 = [
  {
    id: `1`,
    firstname: `Thierry`,
    lastname: `Gregoire`,
    picturemeuble: placard7,
    picturepersonne: avatar4H,
    star: `5`,
    description: `Je Recommande`,
    descriptionsecond: `M MALLET est intervenu avec beaucoup de compétence et de savoir-faire pour effectuer  la réparation et l'entretien de mes portes de cuisine équipée.
Je le recommande très vivement pour tous vos travaux relevant de son activité professionnelle`,
  },
  {
    id: `2`,
    firstname: `Cynthia`,
    lastname: `Gay`,
    picturemeuble: placard8,
    picturepersonne: avatar4F,
    star: `5`,
    description: `Hônnete`,
    descriptionsecond: `Après avoir contacté la Société « KAZED Spécialiste des placards », celle-ci nous a recommandé M. MALLET, afin de rénover un grand placard en vieux bois dans une chambre.
M. MALLET s’est déplacé pour établir le devis d’après les mesures prises.
Ce travail est maintenant terminé dans les délais et nous pouvons affirmer que c’est un excellent menuisier, il est très rigoureux. Son travail est impeccable et les finitions parfaites. Il est également ponctuel, sérieux, très honnête. Son devis est respectable et défit toute concurrence.
Nous pouvons recommander les yeux fermés.`,
  },
  {
    id: `3`,
    firstname: `Jeannine`,
    lastname: `Durelle`,
    picturemeuble: table,
    picturepersonne: avatar2F,
    star: `5`,
    description: `Soigné`,
    descriptionsecond: `Olivier Mallet est un artisan de bons conseils (couleur et matériaux) son travail est très soigné. Coordonnées à garder précieusement.`,
  },
  {
    id: `4`,
    firstname: `Daniel`,
    lastname: `Vogel`,
    picturemeuble: meubletele,
    picturepersonne: avatar2H,
    star: `5`,
    description: `Pro`,
    descriptionsecond: `Le travail a été exécuté dans les délais, suivant le devis convenu, par un pro de contact sympa ; merci.`,
  },
  {
    id: `5`,
    firstname: `Kamel`,
    lastname: `Lakhlifi`,
    picturemeuble: bibliothèque,
    picturepersonne: avatar1H,
    star: `5`,
    description: `Maîtrise`,
    descriptionsecond: `J'ai fait appel à M. MALLET pour la réalisation de quatre dressing et deux cuisines sur mesure, le résultat est juste magique, il maîtrise la découpe et l'installation, je suis très satisfait et ferait appel à lui pour les projets futurs, c'est sûr.`,
  },
  {
    id: `6`,
    firstname: `Nicolas`,
    lastname: `Gaudilliere`,
    picturemeuble: bibliothèque1,
    picturepersonne: avatar3H,
    star: `5`,
    description: `Précis`,
    descriptionsecond: `QuaTravail de qualité réalisé pour la remise en état d’une terrasse en bois. Devis précis et tenu. Et en plus très sympathique. A recommander vivement.`,
  },
];

export const mediasociaux = [
  {
    id: `1`,
    picture: fb,
    href: "https://www.facebook.com/OlivierMalletMenuisier",
  },
  {
    id: `2`,
    picture: linkedin,
    href: "http://www.linkedin.com/in/menuiseriemallet",
  },
  {
    id: `3`,
    picture: whatsapp,
    href: "https://wa.me/qr/2CZSNP22NHRRA1",
  },
  {
    id: `4`,
    picture: insta,
    href: "https://instagram.com/mallet_menuiseries?igshid=NGVhN2U2NjQ0Yg==",
  },
];

export const services = [
  {
    id: `1`,
    picture: agencement,
    name: `Agencement`,
    txt: [
      `Identification des besoins et souhaits adaptés à un budget prédéfini `,
      `Mesure des espaces disponibles à aménager `,
      `Choix du devis  conforme au budget et projet`,
    ],
    button: `Contact`,
    href: "/Contact",
  },
  {
    id: `2`,
    picture: menuiseriedessin,
    name: `Menuiserie`,
    txt: [
      `Menuiseries sur mesure selon les dimensions nécessaires pour répondre à vos besoins, vos choix et respecter vos exigences`,
    ],
    button: `Devis`,
    href: "/Contact",
  },
  {
    id: `3`,
    picture: cuisine,
    name: `Cuisine`,
    txt: [
      `Pose de nouveaux éléments de cuisine`,
      `Raccordement de l’évier`,
      `Installation de prises électriques supplémentaires`,
    ],
    button: `Contact`,
    href: "/Contact",
  },
  {
    id: `4`,
    picture: dressing,
    name: `Dressing`,
    txt: [`Création et montage de dressing sur-mesure `],
    button: `Devis`,
    href: "/Contact",
  },
  {
    id: `5`,
    picture: rénovation,
    name: `Rénovation`,
    txt: [
      `Rénovation de tous types de meubles`,
      `Renfort pour une meilleur stabilité`,
    ],
    button: `Contact`,
    href: "/Contact",
  },
  {
    id: `6`,
    picture: parquet,
    name: `Parquet`,
    txt: [`Tous types de poses pour un parquet massif ou contrecollé`],
    button: `Devis`,
    href: "/Contact",
  },
];

export const galerie1 = [
  {
    picture: placard1,
  },
  {
    picture: placard2,
  },
  {
    picture: placard3,
  },
  {
    picture: placard4,
  },
  {
    picture: placard5,
  },
  {
    picture: placard6,
  },
];

export const galerie2 = [
  {
    picture: placard7,
  },
  {
    picture: placard8,
  },
  {
    picture: table,
  },
  {
    picture: meubletele,
  },
  {
    picture: bibliothèque,
  },
  {
    picture: bibliothèque1,
  },
];
