import React from "react";

import {
  Navbar,
  Backgroundpage,
  Form,
  Information,
  Footer,
  MyGoogleMap,
} from "../components";

export default function Contact() {
  return (
    <div>
      <Navbar />
      <Backgroundpage />
      <div className="form-bigcontainer">
        <Form />
        <Information />
      </div>
      <div className="bloc-time">
        <p className="txt-time">
          Horaires&nbsp;d'
          <span>ouverture</span>
        </p>
        <div className="border-vertical"></div>
        <div className="border-horizontal"></div>
        <div className="section-time">
          <div className="box-left">
            <p>Lun. - Ven.</p>
            <p>Samedi</p>
            <p>Dimanche</p>
          </div>
          <div className="box-right">
            <p>8 H - 19 H</p>
            <p>Fermé</p>
            <p>Fermé</p>
          </div>
        </div>
      </div>
      <div className="map">
        <MyGoogleMap />
      </div>
      <Footer />
    </div>
  );
}
