// Import des modules nécessaires depuis React
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Import des constantes et des assets
import { navLinks } from "../constans"; // Import des liens de navigation
import { logo, menu, close } from "../assets"; // Import des images pour le logo, le menu et la fermeture

// Composant Navbar
export default function Navbar() {
  // Utilisation du hook useState pour gérer l'état du menu ouvert/fermé
  const [toggle, setToggle] = useState(false);

  // Utilisation du hook useLocation pour obtenir l'emplacement courant de la page
  const location = useLocation();

  // Fonction pour gérer la fermeture du menu lorsqu'un lien est cliqué
  const handleNavLinkClick = () => {
    setToggle(false); // Ferme la liste de navigation
  };

  return (
    <nav>
      {/* Conteneur pour le logo et le titre */}
      <div className="container-logo">
        {/* Lien du logo qui ramène en haut de la page */}
        <Link
          to="/"
          className=""
          onClick={() => {
            setToggle(false); // Ferme la liste de navigation
            window.scrollTo(0, 0); // Faire défiler vers le haut de la page
          }}
        >
          {/* Logo */}
          <img src={logo} alt="logo" className="logo" />
          {/* Titre de la navbar */}
          <h1 className="navbar-title">
            Olivier &nbsp;<span>| Menuiserie Mallet</span>
          </h1>
        </Link>

        {/* Conteneur pour la liste de navigation */}
        <div className="container-nav">
          {/* Liste des liens de navigation */}
          <ul style={{ display: toggle ? "flex" : "none" }}>
            {navLinks.map((link) => (
              <li key={link.id}>
                {/* Lien de navigation avec classe "active" si c'est l'élément actif */}
                <Link
                  to={`/${link.id}`} // Chemin relatif pour chaque lien
                  className={`navlink ${
                    location.pathname === `/${link.id}` ? "active" : ""
                  }`}
                  onClick={handleNavLinkClick}
                >
                  {/* Titre principal du lien */}
                  <span
                    className={`title1 navtitle ${
                      location.pathname === `/${link.id}` ? "active" : ""
                    }`}
                  >
                    {link.title1}
                  </span>
                  {/* Titre secondaire du lien */}
                  <span className="title2">{link.title2}</span>
                </Link>
              </li>
            ))}
          </ul>

          {/* Bouton de menu qui ouvre/ferme la liste de navigation */}
          <img
            src={toggle ? close : menu}
            alt="menu"
            className={toggle ? "close" : "menu"}
            onClick={() => setToggle(!toggle)} // Inverser l'état du menu
          />
        </div>
      </div>
    </nav>
  );
}
