import React from "react";
import { useLocation } from "react-router-dom";
import { imagesbg } from "../constans"; // Assurez-vous que le chemin d'importation est correct

// Importez vos images ici (bgmenu et aproposbg) avec les chemins corrects
//import { bgmenu } from "../assets";

export default function Backgroundpage({ children }) {
  const location = useLocation();
  const currentImage = imagesbg.find((item) => item.url === location.pathname);

  // Si l'image correspondante n'est pas trouvée, utilisez une image par défaut (bgmenu)
  const imageUrl = currentImage
    ? currentImage.image
    : imagesbg.find((item) => item.url === "/*").image;

  // Déterminez si la page est la page "contact"
  const isContactPage = location.pathname === "/Contact";

  return (
    <div className={`image-container ${isContactPage ? "contact-page" : ""}`}>
      <img src={imageUrl} alt="background"></img>
      {children}
    </div>
  );
}
