import React from "react";

import { star, starvide } from "../assets";

export default function Client({ avis, className, txt }) {
  return (
    <div className={`container ${className}`}>
      <div className="container-txt">
        {txt.map((txtsupp, index) => (
          <React.Fragment key={index}>{txtsupp.clientsatisfait}</React.Fragment>
        ))}
      </div>
      <div className="section-card">
        <div className="container-card">
          {avis.slice(0, 3).map((client) => (
            <div key={client.id} className="card">
              <div className="undercard">
                <div className="top">
                  <img src={client.picturemeuble} alt="interieurmaison" />
                </div>
                <div className="picturepersonne">
                  <img src={client.picturepersonne} alt={client.id} />
                </div>
                <div className="bot">
                  <div className="botcontainer1">
                    <div className="botcontainer1-left">{client.firstname}</div>
                    <div className="botcontainer1-right">{client.lastname}</div>
                  </div>
                  <div className="botcontainer2">
                    <div className="blockstar">
                      {[...Array(Math.max(5 - parseInt(client.star), 0))].map(
                        (_, index) => (
                          <img key={index} src={starvide} alt="starvide" />
                        )
                      )}
                      {[...Array(Math.min(parseInt(client.star), 5))].map(
                        (_, index) => (
                          <img key={index} src={star} alt="star" />
                        )
                      )}
                    </div>
                  </div>
                  <div className="botcontainer3">
                    <div className="botcontainer3-border"></div>
                  </div>
                  <div className="botcontainer4">
                    <p>“{client.description}...”</p>
                    <br />
                    <p className="secondtxt">“{client.descriptionsecond}”</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="container-card">
          {avis.slice(3, 6).map((client) => (
            <div key={client.id} className="card">
              <div className="undercard">
                <div className="top">
                  <img src={client.picturemeuble} alt="interieurmaison" />
                </div>
                <div className="picturepersonne">
                  <img src={client.picturepersonne} alt={client.id} />
                </div>
                <div className="bot">
                  <div className="botcontainer1">
                    <div className="botcontainer1-left">{client.firstname}</div>
                    <div className="botcontainer1-right">{client.lastname}</div>
                  </div>
                  <div className="botcontainer2">
                    <div className="blockstar">
                      {[...Array(Math.max(5 - parseInt(client.star), 0))].map(
                        (_, index) => (
                          <img key={index} src={starvide} alt="starvide" />
                        )
                      )}
                      {[...Array(Math.min(parseInt(client.star), 5))].map(
                        (_, index) => (
                          <img key={index} src={star} alt="star" />
                        )
                      )}
                    </div>
                  </div>
                  <div className="botcontainer3">
                    <div className="botcontainer3-border"></div>
                  </div>
                  <div className="botcontainer4">
                    <p>“{client.description}...”</p>
                    <br />
                    <p className="secondtxt">“{client.descriptionsecond}”</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
