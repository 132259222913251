import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home, About, Services, Galerie, Contact, Error } from "./pages";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/A_Propos" element={<About />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Galerie" element={<Galerie />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/*" element={<Error />} />
    </Routes>
  );
}

export default App;
